import React from "react";
import styled from "styled-components";
import {
  Annotation,
  MultilineAnnotation,
  MultilineAnnotationClass,
  MultilineAnnotationClassesWithCount,
  UUID,
  MultilineCategory
} from "../models";
import {
  AnnotationType,
  setSelectedMultilineClass,
  setSelectedMultilineCategory,
  beginAnnotation,
  SelectedAnnotationType,
  cancelImageAnnotation
} from "../slices/caseImageViewer";
import { formatMicrometers } from "../utils";
import { useAppDispatch, useAppSelector } from "../hooks";

const InfoSidebarCeliacTableStyled = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px 10px 0 0;
  border: solid thin #e5e5e5;
`;

const InfoSidebarCeliacRowStyled = styled.tr`
  height: 5rem;
`;

interface StyleProps {
  readonly highlighted?: boolean;
  readonly highlightColor?: string;
}

const InfoSidebarCeliacCellStyled = styled.td`
  text-align: center;
  min-width: 2rem;
  vertical-align: middle;
  border: ${(props: StyleProps) =>
    props.highlighted ? `solid thin ${props.highlightColor} !important` : "inherit"};
`;

const InfoSidebarCeliacHeaderCellStyled = styled.th`
  text-align: center;
  min-width: 2rem;
  vertical-align: middle;
  background-color: #f4f4f4;
`;

const InfoSidebarCeliacTable = ({
  annotationClasses,
  annotations,
  imageId
}: {
  readonly annotationClasses: MultilineAnnotationClassesWithCount;
  readonly annotations: ReadonlyArray<Annotation>;
  readonly imageId: UUID;
}) => {
  const dispatch = useAppDispatch();

  const multilineAnnotations = annotations.filter(
    annotation => annotation.annotationType == AnnotationType.Multiline
  ) as MultilineAnnotation[];

  const selectedAnnotationClass = useAppSelector(
    state => state.caseImageViewer.selectedMultilineClass
  );
  const setSelectedAnnotationClass = (annotationClass: MultilineAnnotationClass) => {
    dispatch(setSelectedMultilineClass(annotationClass));
  };

  const selectedAnnotationCategory = useAppSelector(
    state => state.caseImageViewer.selectedMultilineCategory
  );
  const setSelectedAnnotationCategory = (category: MultilineCategory) => {
    dispatch(setSelectedMultilineCategory(category));
  };

  return annotationClasses.length == 0 ? null : (
    <InfoSidebarCeliacTableStyled className="info-sidebar-celiac-table">
      <thead>
        <InfoSidebarCeliacRowStyled>
          <InfoSidebarCeliacHeaderCellStyled />
          <InfoSidebarCeliacHeaderCellStyled>Villus Height</InfoSidebarCeliacHeaderCellStyled>
          <InfoSidebarCeliacHeaderCellStyled>Crypt Depth</InfoSidebarCeliacHeaderCellStyled>
        </InfoSidebarCeliacRowStyled>
      </thead>
      <tbody>
        {annotationClasses.flatMap(annotationClass => {
          if (selectedAnnotationClass === undefined) {
            setSelectedAnnotationClass(annotationClass.annotationClass);
          }

          const multilineClassAnnotations = multilineAnnotations.filter(
            annotation => annotation.annotationClassId == annotationClass.annotationClass.id
          );

          const villusHeight = multilineClassAnnotations.find(
            annotation => annotation.multilineCategory == MultilineCategory.VillusHeight
          )?.length;
          const cryptDepth = multilineClassAnnotations.find(
            annotation => annotation.multilineCategory == MultilineCategory.CryptDepth
          )?.length;

          const onCellClicked = (
            annotationClass: MultilineAnnotationClass,
            category: MultilineCategory,
            cellValue: number | null | undefined
          ) => {
            return (event: React.MouseEvent) => {
              setSelectedAnnotationClass(annotationClass);
              setSelectedAnnotationCategory(category);
              if (!cellValue) {
                dispatch(
                  beginAnnotation({
                    selectedAnnotationType: {
                      type: AnnotationType.Multiline
                    } as SelectedAnnotationType,
                    imageId: imageId
                  })
                );
              } else {
                dispatch(cancelImageAnnotation());
              }
              event.preventDefault();
            };
          };

          const selectedAnnotationClassId = selectedAnnotationClass && selectedAnnotationClass.id;

          return (
            <InfoSidebarCeliacRowStyled key={annotationClass.annotationClass.id}>
              <InfoSidebarCeliacCellStyled
                style={{
                  backgroundColor: annotationClass.annotationClass.color,
                  color: "white"
                }}
              >
                {annotationClass.annotationClass.name}
              </InfoSidebarCeliacCellStyled>
              <InfoSidebarCeliacCellStyled
                style={{ cursor: "pointer" }}
                highlighted={
                  selectedAnnotationClassId === annotationClass.annotationClass.id &&
                  selectedAnnotationCategory === MultilineCategory.VillusHeight
                }
                highlightColor={annotationClass.annotationClass.color}
                onClick={onCellClicked(
                  annotationClass.annotationClass,
                  MultilineCategory.VillusHeight,
                  villusHeight
                )}
              >
                {villusHeight ? formatMicrometers(villusHeight) : ""}
              </InfoSidebarCeliacCellStyled>
              <InfoSidebarCeliacCellStyled
                style={{ cursor: "pointer" }}
                highlighted={
                  selectedAnnotationClassId === annotationClass.annotationClass.id &&
                  selectedAnnotationCategory === MultilineCategory.CryptDepth
                }
                highlightColor={annotationClass.annotationClass.color}
                onClick={onCellClicked(
                  annotationClass.annotationClass,
                  MultilineCategory.CryptDepth,
                  cryptDepth
                )}
              >
                {cryptDepth ? formatMicrometers(cryptDepth) : ""}
              </InfoSidebarCeliacCellStyled>
            </InfoSidebarCeliacRowStyled>
          );
        })}
      </tbody>
    </InfoSidebarCeliacTableStyled>
  );
};

export default InfoSidebarCeliacTable;
